<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name" label="用户编号">
                <el-input v-model="searchInfo.userNo" @change="userNameChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name" label="用户名称">
                <el-input v-model="searchInfo.userName" @change="userNameChange" clearable></el-input>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="success" size="mini" icon="el-icon-search" plain @click="searchList()">查询</el-button>
                <el-button size="mini" icon="el-icon-refresh-left" plain @click="addKC()">重置</el-button>
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {}
        }
    },
    computed: {},
    created() {},
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addKC() {
            this.$emit('addTemplate')
        },
        //有效期设置
        yxqsz() {
            this.$emit('yxqSetting')
        },
        uesrNoChange(e) {
            this.searchInfo.userNo = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        userNameChange(e) {
            this.searchInfo.userName = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 20px 0 0px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
